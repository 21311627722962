/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import Header from "./header"
import "./layout.css"
import Navbar from "./Navbar/Navbar"
import styled from "styled-components"
import TalkboxLogo from '../images/talkbox-brandname.svg';
import FacebookLogo from '../images/if-facebook-circle.svg';
import TwitterLogo from '../images/if-twitter-circle.svg';
import YouTubeLogo from '../images/if-youtube-circle.svg';
import "../styles/global.css"

const Layout = ({ children }) => {

  return (
    <>
      <Navbar />
      <div
        style={{
          margin: `0 auto`,
          maxWidth: 1440,
          // padding: `0 1.0875rem 1.45rem`,
        }}
      >
        <main>{children}</main>
        <footer>
          <div className="w3-row nav-group">
            <div className="w3-col m12 l6">
              <img className="talkbox-badge" src={TalkboxLogo} alt="talkbox logo" />
              <p className="mission-statement"><span className="spacing">創立於 2011年</span>熱衷以通訊軟件聯繫所有人<br />心繫香港的開發團隊</p>
              <a href="https://www.facebook.com/Teamwork-App-2209344859340935/" target="_blank" className="scrolled"><img src={FacebookLogo} className="facebook" width="32" height="32" /></a>
              <a href="https://twitter.com/talkboxapp" target="_blank" className="scrolled"><img className="twitter" src={TwitterLogo} width="32" height="32" /></a>
              <a href="https://www.youtube.com/channel/UCoM5ThcqOcqaS1ddmeGGB9g" target="_blank" className="scrolled"><img className="youtube" src={YouTubeLogo} width="32" height="32" /></a>
            </div>
            <div className="nav-section w3-col m12 l3">
              <nav className="scrolled">
                <h4>產品資訊</h4>
                <ul>
                  <li><a href="https://teamworkapp.co/about-us" target="_blank" className="scrolled">關於我們</a></li>
                  <li><a href="https://help.teamworkapp.co" target="_blank" className="scrolled">技術支援服務</a></li>
                </ul>
              </nav>
            </div>
            <div className="nav-section w3-col m12 l3">
              <nav className="scrolled">
                <h4>Grick 私隱權</h4>
                <ul>
                  <li><a href="https://teamworkapp.co/privacy" target="_blank" className="scrolled">隱私權政策</a></li>
                  <li><a href="https://teamworkapp.co/terms" target="_blank" className="scrolled">服務使用條款</a></li>
                  <li><a href="https://teamworkapp.co/cookies-policy" target="_blank" className="scrolled">Grick 與 Cookies</a></li>
                  <li><a href="https://teamworkapp.co/gdpr" target="_blank" className="scrolled">通用數據保障條例</a></li>
                </ul>
              </nav>
            </div>
          </div>
          © {new Date().getFullYear()} TALKBOX 有限公司 版權所有
        </footer>
      </div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
