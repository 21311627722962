import React from "react"
import styled from "styled-components"
import Img from "gatsby-image"
import { Link, useStaticQuery, graphql } from "gatsby"

import GrickLogo from '../../images/grick-brandname.svg';

const LogoWrap = styled.div`
  margin: auto 0;
  flex: 0 1 150px;

  @media (max-width: 768px) and (orientation: landscape) {
    flex: 0 1 200px;
  }
`
const Logo = () => {

  return (
    <LogoWrap as={Link} to="/">
      <img src={GrickLogo} alt="talkbox logo" />
    </LogoWrap>
  )
}

export default Logo