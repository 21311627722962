import React from "react"

import styled from "styled-components"
import { Link } from "gatsby"

const NavItem = styled(Link)`
  text-decoration: none;
  color: #242a25;
  display: inline-block;
  white-space: nowrap;
  margin: 0 1vw;
  transition: all 200ms ease-in;
  position: relative;
  font-family: 'Roboto', sans-serif;
  font-weight: bold;
  font-size: 15px;

  :after {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    width: 0%;
    content: ".";
    color: transparent;
    background: goldenrod;
    height: 1px;
    transition: all 0.4s ease-in;
  }

  :hover {
    color: goldenrod;
    ::after {
      width: 100%;
    }
  }

  // :last-child {
  //   background: #1998d1;
  //   border-radius: 6px;
  //   padding: 10px 30px;
  //   color: #FFFFFF;
  //   font-weight: bold;
  // }

  // :last-child:hover {
  //   background: #27B2F1;
  // }

  @media (max-width: 768px) {
    padding: 20px 0;
    font-size: 1.5rem;
    z-index: 6;
  }
`
const NavbarLinks = () => {
  return (
    <>
      <NavItem to="/pricing/">定價</NavItem>
      <NavItem to="/">產品介紹</NavItem>
      {/* <NavItem to="/">幫助中心</NavItem>
      <NavItem to="/">團隊網誌</NavItem>
      <NavItem to="/">立即試用</NavItem> */}
    </>
  )
}

export default NavbarLinks